import React, { useEffect } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { handlePrintDetails } from "../../helpers/commonHelper";
import MetricsCard from "../components/MetricsCard";
import SectionHeader from "../components/SectionHeader";
import useQueryData from "../../hooks/useQueryDataHistorical";
import { useDispatch, useSelector } from "react-redux";
import { GridLoader } from "react-spinners";
import {
  fetchAsyncMetricCPUData,
  fetchAsyncMetricDiskData,
  fetchAsyncMetricMemoryData,
  fetchAsyncMetricOtherData,
  getMetricsCPUData,
  getMetricsDiskData,
  getMetricsMemoryData,
  getMetricsOtherData,
} from "../../store/slices/enterpriseServer/metricsSlice";

function MetricsPage() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const serverId = searchParams.get("id");
  const host = searchParams.get("host");
  const qd = useQueryData();
  const dispatch = useDispatch();
  const cpuData = useSelector(getMetricsCPUData);
  const diskData = useSelector(getMetricsDiskData);
  const memoryData = useSelector(getMetricsMemoryData);
  const otherData = useSelector(getMetricsOtherData);

  useEffect(() => {
    // if(!uuid?.loading && uuid.data.uniqueid){
    dispatch(fetchAsyncMetricCPUData(qd));
    dispatch(fetchAsyncMetricDiskData(qd));
    dispatch(fetchAsyncMetricMemoryData(qd));
    dispatch(fetchAsyncMetricOtherData(qd));
    // }
  }, [dispatch]);

  const handleNavigation = (serverName, page) => {
    let myString = serverName;
    // myString = myString.replace(/\s+/g, "-");
    const params = { id: serverId, host: host, viewType: myString };
    navigate({
      pathname: `${page}`,
      search: `?${createSearchParams(params)}`,
    });
  };
  let breadCrumbsList = [
    {
      id: 1,
      name: "Enterprise",
      url: "/enterprise-server",
    },
    {
      id: 2,
      name: serverId,
      url: `/metrics?id=${serverId}`,
    },
    {
      id: 3,
      name: host,
      url: ``,
    },
  ];

  return (
    <div className="enterprise_wrapper">
      <SectionHeader
        title={"Metrics"}
        btnClickHandler={handlePrintDetails}
        breadCrumbsList={breadCrumbsList}
      />
      <div className="metrics_card_wrapper">
        {cpuData.loading && (
          <div>
            <GridLoader color="#366bd6" />
          </div>
        )}
        {cpuData?.data?.data?.map((item, idx) => {
          if (item.dtype === "cpu_ms" || item.dtype === "cpw") {
            // eslint-disable-next-line array-callback-return
            return;
          } else {
            return (
              <MetricsCard
                key={idx}
                cpuData={item}
                cardClickHandler={() =>
                  handleNavigation(item.dtype, "/detailed-view-cpu")
                }
                {...item}
              />
            );
          }
        })}
        {diskData.loading && (
          <div>
            <GridLoader color="#366bd6" />
          </div>
        )}
        {otherData?.data?.data?.map((item, idx) => {
          if (item.dtype === "read_write_ratio") {
            // eslint-disable-next-line array-callback-return
            return;
          } else {
            return (
              <MetricsCard
                key={idx}
                cpuData={item}
                cardClickHandler={() =>
                  handleNavigation(item.dtype, "/detailed-view-other")
                }
                {...item}
              />
            );
          }
        })}
        {diskData?.data?.data?.map((item, idx) => {
          if (item.dtype === "read_write_ratio") {
            // eslint-disable-next-line array-callback-return
            return;
          } else {
            return (
              <MetricsCard
                key={idx}
                cpuData={item}
                cardClickHandler={() =>
                  handleNavigation(item.dtype, "/detailed-view-disk")
                }
                {...item}
              />
            );
          }
        })}
        {memoryData?.data?.data?.map((item, idx) => {
          if (
            item.dtype === "machine_pool_faulting" ||
            item.dtype === "pool_faulting_rate" ||
            item.dtype === "memory_size_faulting" ||
            item.dtype === "TopPoolFaultingRate"
          ) {
            // eslint-disable-next-line array-callback-return
            return;
          } else {
            return (
              <MetricsCard
                key={idx}
                cpuData={item}
                cardClickHandler={() =>
                  handleNavigation(item.dtype, "/detailed-view-memory")
                }
                {...item}
              />
            );
          }
        })}
      </div>
    </div>
  );
}

export default MetricsPage;
