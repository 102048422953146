import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Table } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import {
  checkRealTimeSystemStatus,
  handlePrintDetails,
  metricCalculation,
} from "../../helpers/commonHelper";
import CanvasJSReact from "../../scripts/canvasjs.react";
import { ReportDetailsTableJSON, TopJobCPU } from "../../utils/constant";
import SectionHeader from "../components/SectionHeader";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import TopJobsData from "../../assets/top_jobs_data.json";
import chartDataSyncIO from "../../assets/pie_data_disk_sync_ios.json";
import chartDataAsyncIO from "../../assets/pie_data_disk_async_ios.json";
import chartDataTotalIO from "../../assets/chart_data_total_ios.json";
import * as _ from "lodash";
import {
  fetchAsyncMetricDiskData,
  getMetricsDiskData,
} from "../../store/slices/enterpriseServer/metricsSlice";
import useQueryData from "../../hooks/useQueryDataHistorical";
import { useDispatch, useSelector } from "react-redux";
import { GridLoader } from "react-spinners";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function ReportDetails() {
  let [searchParams] = useSearchParams();
  const serverId = searchParams.get("id");
  const host = searchParams.get("host");
  const viewType = searchParams.get("viewType");
  const [activeInternalTabID, setInternalActiveTabID] = useState(2);
  const [activeTabID, setActiveTabID] = useState(1);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const qd = useQueryData();
  const dispatch = useDispatch();
  const diskData = useSelector(getMetricsDiskData);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    // if(!uuid?.loading && uuid.data.uniqueid){
    dispatch(fetchAsyncMetricDiskData(qd));
    // }
  }, [dispatch]);

  let breadCrumbsList = [
    {
      id: 1,
      name: "Enterprise",
      url: "/enterprise-server",
    },
    {
      id: 2,
      name: serverId,
      url: `/metrics?id=${serverId}`,
    },
    {
      id: 3,
      name: host,
      url: `/metrics?id=${serverId}&host=${host}`,
    },
    {
      id: 4,
      name: viewType,
      url: ``,
    },
  ];

  const ViewTypeList = {
    "CPU-ms": "CPU ms",
    no_of_cores: "No. of Cores",
    faulting_rate: "Faulting Rate (Faults/Sec)",
    cache_hit_perc: "Cache Hit Percentage",
    total_disk_ops: "Total Disk Operations",
    cpu_utilization: "CPU Utilization",
    read_write_ratio: "Read Write Ration",
    executing_summary: "Executive Summary",
    disk_response_time: "Disk Response Time",
    pool_faulting_rate: "Pool Faulting Rate",
    response_time_5250: "5250 Response Time",
    total_transactions: "Total Transactions",
    disk_arm_utilization: "Disk Arm Utilization",
    memory_size_faulting: "Memory vs Size Faulting",
    top_jobs_utilisation: "Top Jobs Utilization",
    machine_pool_faulting: "Machine Pool Faulting",
    system_specifications: "System Specifications",
    disk_space_utilization: "Disk Space Utilization",
    top_pool_faulting_rate: "Top Pool Faulting Rate",
    ethernet_line_utilization: "Ethernet Line Utilization",
    "CPW-Utilization": "CPW Utilization",
  };

  const optionsSyncIO = {
    exportEnabled: true,
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Percentage Breakdown ",
      fontSize: 15,
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "left",
    },
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y} %",
        showInLegend: "true",
        legendText: "{label} - {y}%",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: chartDataSyncIO.map(({ total, jobname }) => ({
          y: +total,
          label: jobname,
        })),
      },
    ],
  };
  const optionsAsyncIO = {
    exportEnabled: true,
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Percentage Breakdown ",
      fontSize: 15,
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "left",
    },
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y} %",
        showInLegend: "true",
        legendText: "{label} - {y}%",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: chartDataAsyncIO.map(({ total, jobname }) => ({
          y: +total,
          label: jobname,
        })),
      },
    ],
  };
  const optionsTotalIO = {
    exportEnabled: true,
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Percentage Breakdown ",
      fontSize: 15,
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "left",
    },
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y} %",
        showInLegend: "true",
        legendText: "{label} - {y}%",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: chartDataTotalIO.map(({ total, jobname }) => ({
          y: +total,
          label: jobname,
        })),
      },
    ],
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const summaryData = _.filter(ReportDetailsTableJSON, function (o) {
    return o.metric_type == viewType;
  });

  const renderHeader = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <InputText
          style={{ maxWidth: "15rem" }}
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="form-control"
        />
      </div>
    );
  };

  const header = renderHeader();
  return (
    <>
      <div className="report_details_wrapper">
        <SectionHeader
          title={`Details -  ${ViewTypeList[viewType]}`}
          btnClickHandler={handlePrintDetails}
          breadCrumbsList={breadCrumbsList}
        />
        <div className="table_wrapper">
          {diskData.loading && (
            <div>
              <GridLoader color="#366bd6" />
            </div>
          )}
          {!diskData.loading && (
            <Table bordered>
              <thead>
                <tr>
                  <th>Metric</th>
                  <th>Status</th>
                  <th>Findings</th>
                </tr>
              </thead>
              <tbody>
                {diskData?.data?.data?.map((item) => {
                  if (item.dtype === viewType) {
                    return (
                      <tr key={item.id}>
                        <td>{item.dtypedesc}</td>
                        <td>
                          <span className={checkRealTimeSystemStatus(item)}>
                            {checkRealTimeSystemStatus(item)}
                          </span>
                        </td>
                        <td className="findings_col">
                          <div className={checkRealTimeSystemStatus(item)}>
                            {metricCalculation(item)}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  // eslint-disable-next-line array-callback-return
                  // return;
                })}
                {/* {summaryData.map((data) => {
              return (
                <tr key={data.id}>
                  <td>{ViewTypeList[viewType]}</td>
                  <td>
                    <span className={data.metric_status}>
                      {data.metric_health}
                    </span>
                  </td>
                  <td className="findings_col">
                    {data.findings.map((d) => (
                      <div key={d.id} className={d.type}>
                        {d.msg}
                      </div>
                    ))}
                  </td>
                </tr>
              );
            })} */}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="topjobs-conatiner">
          <div>
            <DataTable
              value={TopJobsData}
              header={header}
              paginator
              className="systems-table"
              showGridlines
              rows={10}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              dataKey="id"
              filterDisplay="menu"
              responsiveLayout="scroll"
              filters={filters}
              globalFilterFields={[
                "jbname",
                "jbuser",
                "jbnbr",
                "cpu",
                "totalios",
                "syncios",
                "asyncios",
                "faults",
              ]}
              emptyMessage="No Jobs Found"
            >
              <Column header="Job Name" field="jbname" sortable></Column>
              <Column header="User" field="jbuser" sortable></Column>
              <Column header="Job Type" field="jbnbr" sortable></Column>
              <Column header="Total IOs" field="totalios" sortable></Column>
              <Column header="Sync IOs" field="syncios" sortable></Column>
              <Column header="Async IOs" field="asyncios" sortable></Column>
            </DataTable>
          </div>
        </div>
        <div className="topjobs-conatiner">
          <TabContent activeTab={String(activeTabID)}>
            <TabPane tabId="1">
              {/* inside pie  tabs  under performance insights starts */}
              <Nav tabs className="pie-inside-tabbing">
                <NavItem>
                  <NavLink
                    className={activeInternalTabID === 2 ? "active" : ""}
                    onClick={() => setInternalActiveTabID(2)}
                  >
                    Total IOs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeInternalTabID === 3 ? "active" : ""}
                    onClick={() => setInternalActiveTabID(3)}
                  >
                    Sync IOs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeInternalTabID === 4 ? "active" : ""}
                    onClick={() => setInternalActiveTabID(4)}
                  >
                    Async IOs
                  </NavLink>
                </NavItem>
              </Nav>
              {/* inside pie  tabs data under performance insights ends */}

              {/* inside pie  tabs data under performance insights starts */}
              <TabContent activeTab={String(activeInternalTabID)}>
                <TabPane tabId="1">
                  <div className="pie-chart-wrapper"></div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="pie-chart-wrapper">
                    <CanvasJSChart options={optionsSyncIO} title={"Sync IOs"} />
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="pie-chart-wrapper">
                    <CanvasJSChart
                      options={optionsAsyncIO}
                      title={"Async IOs"}
                    />
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="pie-chart-wrapper">
                    <CanvasJSChart
                      options={optionsTotalIO}
                      title={"Total IOs"}
                    />
                  </div>
                </TabPane>
              </TabContent>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
}

export default ReportDetails;
