import React, { useRef } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { handlePrintDetails } from "../../helpers/commonHelper";
import ChartView from "../components/ChartView";
import MetricsCard from "../components/MetricsCardSys";
import SectionHeader from "../components/SectionHeader";
function HostView() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const serverId = searchParams.get("id");
  const container1 = useRef(null);
  const container2 = useRef(null);

  const handleNavigation = (id) => {
    const params = { id: serverId, host: id };
    navigate({
      pathname: "/metrics",
      search: `?${createSearchParams(params)}`,
    });
  };
  let breadCrumbsList = [
    {
      id: 1,
      name: "Enterprise",
      url: "/enterprise-server",
    },
    {
      id: 2,
      name: serverId,
      url: "",
    },
  ];
  let arr = [
    {
      id: 1,
      serverName: "prod",
      type: "good",
      msg: "Good",
      health: "90%",
      serverLocation: "Chicago, IL",
      systemType: "Frame",
    },
    {
      id: 2,
      serverName: "prod",
      type: "critical",
      msg: "Critical",
      health: "90%",
      serverLocation: "Chicago, IL",
      systemType: "Frame",
    },
    {
      id: 3,
      serverName: "prod",
      type: "critical",
      msg: "Critical",
      health: "90%",
      serverLocation: "Chicago, IL",
      systemType: "Frame",
    },
  ];

  const allowDragDrop = (e) => {
    e.preventDefault();
  };
  const drag = (e) => {
    const { id } = e.target;
    e.dataTransfer.setData("dragID", id);
  };
  const drop = (e) => {
    e.preventDefault();
    var id = e.dataTransfer.getData("dragID");
    let element = document.getElementById(id);
    if (id === "drag1") {
      container2.current.appendChild(element);
    } else if (id === "drag2") {
      container1.current.appendChild(element);
    }
  };
  return (
    <div className="enterprise_wrapper">
      <SectionHeader
        title={"LPAR / Host"}
        btnClickHandler={handlePrintDetails}
        breadCrumbsList={breadCrumbsList}
      />
      <div className="servers_wrapper">
        {/* <div
          className="dragdrop-card left-box"
          onDragOver={allowDragDrop}
          onDrop={drop}
          ref={container1}
        > */}
        <div className="metrics_card_wrapper">
          {arr.map((item) => (
            <MetricsCard
              key={item.id}
              keyID={item.id}
              cardClickHandler={() => handleNavigation(item.serverName)}
              {...item}
            />
          ))}
        </div>
        {/* </div> */}
        {/* <div
          className="dragdrop-card right-box"
          onDragOver={allowDragDrop}
          onDrop={drop}
          ref={container2}
        >
          <div
            className="chart_view_wrapper"
            draggable="true"
            onDragStart={drag}
            id="drag2"
          >
            <ChartView />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default HostView;
