import React from "react";
import { checkPeakSystemStatus, metricCalculation } from "../../helpers/commonHelper";

function MetricsCard(props) {
  const { cpuData, keyID, type, msg, cardClickHandler } = props;
  
  return (
    <div
      className={`metricsCard ${checkPeakSystemStatus(cpuData)}`}
      onClick={cardClickHandler}
      key={keyID}
    >
      <div className="info">
        <div className="title">
          {cpuData.dtypedesc}: {true}
          {keyID}
        </div>
        <div className="sub_title">{metricCalculation(cpuData, "metricPage")}</div>
      </div>
      <div className="msg">{msg}</div>
    </div>
  );
}

export default MetricsCard;
