import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Table } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import {
  checkRealTimeSystemStatus,
  handlePrintDetails,
  metricCalculation,
} from "../../helpers/commonHelper";
import {
  renderCommonPdMemory,
  getPdMemoryStatus,
} from "../../helpers/summaryHelpers";
import CanvasJSReact from "../../scripts/canvasjs.react";
import { ReportDetailsTableJSON, TopJobCPU } from "../../utils/constant";
import SectionHeader from "../components/SectionHeader";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import TopJobsData from "../../assets/top_jobs_data.json";
import chartDataMemory from "../../assets/pie_data_memory.json";
import tableDataMemeory from "../../assets/table_data_memory.json";
import * as _ from "lodash";
import {
  fetchAsyncSummaryDataOther,
  getExSummaryOtherData,
} from "../../store/slices/datatables/executiveSummarySlice";
import useQueryData from "../../hooks/useQueryDataHistorical";
import { useDispatch, useSelector } from "react-redux";
import { GridLoader } from "react-spinners";
import {
  fetchAsyncMetricOtherData,
  getMetricsOtherData,
} from "../../store/slices/enterpriseServer/metricsSlice";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function ReportDetails() {
  let [searchParams] = useSearchParams();
  const serverId = searchParams.get("id");
  const host = searchParams.get("host");
  const viewType = searchParams.get("viewType");
  const [activeInternalTabID, setInternalActiveTabID] = useState(1);
  const [activeTabID, setActiveTabID] = useState(1);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const qd = useQueryData();
  const dispatch = useDispatch();
  const otherData = useSelector(getMetricsOtherData);

  useEffect(() => {
    // if(!uuid?.loading && uuid.data.uniqueid){
    dispatch(fetchAsyncMetricOtherData(qd));
    // }
  }, [dispatch]);

  let chartData = [
    {
      jobname: "Greater than 1 sec guideline 10.0%",
      jobtype: "faults",
      total: "0.71",
    },
    {
      jobname: "Less than 1 sec guideline 90.0%",
      jobtype: "faults",
      total: "6.67",
    },
  ];

  let breadCrumbsList = [
    {
      id: 1,
      name: "Enterprise",
      url: "/enterprise-server",
    },
    {
      id: 2,
      name: serverId,
      url: `/metrics?id=${serverId}`,
    },
    {
      id: 3,
      name: host,
      url: `/metrics?id=${serverId}&host=${host}`,
    },
    {
      id: 4,
      name: viewType,
      url: ``,
    },
  ];

  const ViewTypeList = {
    "CPU-ms": "CPU ms",
    no_of_cores: "No. of Cores",
    faulting_rate: "Faulting Rate (Faults/Sec)",
    cache_hit_perc: "Cache Hit Percentage",
    total_disk_ops: "Total Disk Operations",
    cpu_utilization: "CPU Utilization",
    read_write_ratio: "Read Write Ration",
    executing_summary: "Executive Summary",
    disk_response_time: "Disk Response Time",
    pool_faulting_rate: "Pool Faulting Rate",
    response_time_5250: "5250 Response Time",
    total_transactions: "Total Transactions",
    disk_arm_utilization: "Disk Arm Utilization",
    memory_size_faulting: "Memory vs Size Faulting",
    top_jobs_utilisation: "Top Jobs Utilization",
    machine_pool_faulting: "Machine Pool Faulting",
    system_specifications: "System Specifications",
    disk_space_utilization: "Disk Space Utilization",
    top_pool_faulting_rate: "Top Pool Faulting Rate",
    ethernet_line_utilization: "Ethernet Line Utilization",
    "CPW-Utilization": "CPW Utilization",
  };

  const optionsMemory = {
    exportEnabled: true,
    animationEnabled: true,
    theme: "light2",
    title: {
      fontSize: 15,
    },
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y} %",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: chartData.map(({ total, jobname }) => ({
          y: +total,
          label: jobname,
        })),
      },
    ],
  };

  const optionsMemory2 = {
    exportEnabled: true,
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Percentage Breakdown ",
      fontSize: 15,
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "left",
    },
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y} %",
        showInLegend: "true",
        legendText: "{label} - {y}%",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: chartDataMemory.map(({ total, jobname }) => ({
          y: +total,
          label: jobname,
        })),
      },
    ],
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const summaryData = _.filter(ReportDetailsTableJSON, function (o) {
    return o.metric_type == viewType;
  });

  const renderHeader = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <InputText
          style={{ maxWidth: "15rem" }}
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="form-control"
        />
      </div>
    );
  };

  const header = renderHeader();
  return (
    <>
      <div className="report_details_wrapper">
        <SectionHeader
          title={`Details -  ${ViewTypeList[viewType]}`}
          btnClickHandler={handlePrintDetails}
          breadCrumbsList={breadCrumbsList}
        />
        {otherData.loading && (
          <div>
            <GridLoader color="#366bd6" />
          </div>
        )}
        {!otherData.loading && (
          <div className="table_wrapper">
            <Table bordered>
              <thead>
                <tr>
                  <th>Metric</th>
                  <th>Status</th>
                  <th>Findings</th>
                </tr>
              </thead>
              <tbody>
                {otherData?.data?.data?.map((item) => {
                  if (item.dtype === viewType) {
                    return (
                      <tr key={item.id}>
                        <td>{item.dtypedesc}</td>
                        <td>
                          <span className={checkRealTimeSystemStatus(item)}>
                            {checkRealTimeSystemStatus(item)}
                          </span>
                        </td>
                        <td className="findings_col">
                          <div className={checkRealTimeSystemStatus(item)}>
                            {metricCalculation(item)}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  // eslint-disable-next-line array-callback-return
                  // return;
                })}
              </tbody>
            </Table>
          </div>
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div className="topjobs-conatiner">
          <div>
            <DataTable
              value={TopJobsData}
              header={header}
              paginator
              className="systems-table"
              showGridlines
              rows={10}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              dataKey="id"
              filterDisplay="menu"
              responsiveLayout="scroll"
              filters={filters}
              globalFilterFields={[
                "jbname",
                "jbuser",
                "jbnbr",
                "cpu",
                "totalios",
                "syncios",
                "asyncios",
                "faults",
              ]}
              emptyMessage="No Jobs Found"
            >
              <Column header="Job Name" field="jbname" sortable></Column>
              <Column header="User" field="jbuser" sortable></Column>
              <Column header="Job Num" field="jbnbr" sortable></Column>
              <Column header="Response Time" field="faults" sortable></Column>
              <Column
                header="Number of Transactions"
                field="faults"
                sortable
              ></Column>
            </DataTable>
          </div>
        </div>
        <div className="topjobs-conatiner">
          <TabContent activeTab={String(activeTabID)}>
            <TabPane tabId="1">
              {/* inside pie  tabs  under performance insights starts */}
              <Nav tabs className="pie-inside-tabbing">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontSize: "0.8rem", marginTop: "auto" }}>
                    Percentage Breakdown - Response Time
                  </p>
                  <NavItem
                    style={{
                      display: "flex",
                      width: "44%",
                      justifyContent: "space-between",
                    }}
                  >
                    <NavLink
                      className={activeInternalTabID === 1 ? "active" : ""}
                      onClick={() => setInternalActiveTabID(1)}
                      style={
                        activeInternalTabID === 1
                          ? { width: "maxContent", borderRadius: "50px" }
                          : {
                              width: "maxContent",
                              padding: "none",
                              border: "1px solid",
                              borderRadius: "40px",
                            }
                      }
                    >
                      Service Level
                    </NavLink>

                    <NavLink
                      className={activeInternalTabID === 2 ? "active" : ""}
                      onClick={() => setInternalActiveTabID(2)}
                      style={
                        activeInternalTabID === 2
                          ? { width: "maxContent", borderRadius: "50px" }
                          : {
                              width: "maxContent",
                              padding: "none",
                              border: "1px solid",
                              borderRadius: "40px",
                            }
                      }
                    >
                      Total Transactions
                    </NavLink>
                  </NavItem>
                </div>
              </Nav>
            </TabPane>
            <div style={{ borderBottom: "1px solid #e3ebf6" }}></div>
            <TabContent
              activeTab={String(activeInternalTabID)}
              style={{ marginTop: "15px" }}
            >
              <TabPane tabId="1">
                <div className="pie-chart-wrapper">
                  <CanvasJSChart options={optionsMemory} title={"Faults"} />
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="pie-chart-wrapper">
                  <CanvasJSChart options={optionsMemory2} title={"Faults"} />
                </div>
              </TabPane>
            </TabContent>
          </TabContent>
        </div>
      </div>
      <div className="topjobs-container">
        <DataTable
          value={tableDataMemeory}
          stripedRows
          showGridlines
          responsiveLayout="scroll"
        >
          <Column
            field="pool"
            header="Memory Pool"
            align="center"
            style={{ width: "264px" }}
          ></Column>
          <Column
            align="center"
            field={(data) => renderCommonPdMemory(data, data.memory_size)}
            header="Memory (GB)"
          ></Column>
          <Column
            align="center"
            field={(data) => renderCommonPdMemory(data, data.mem_perc)}
            header="% of Memory in Pool"
          ></Column>
          <Column
            align="center"
            field={(data) => renderCommonPdMemory(data, data.faulting_rate)}
            header="Average Faulting Rate (Faults / Sec)"
          ></Column>
        </DataTable>
      </div>
      <p></p>
    </>
  );
}

export default ReportDetails;
